import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/style.css'
const vfm = createVfm()

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import '@/assets/tailwind.css'
import installI18n from './lang/index'

const app = createApp(App)
installI18n(app)

const globalTelOption = {
    defaultCountry: 'fr',
    inputOptions: {
        placeholder: 'Entrez votre numéro de téléphone'
    }
}

app.use(store)
    .use(VueTelInput, globalTelOption)
    .use(createMetaManager())
    .use(vfm)
    .use(router)
    .mount('#app')
