
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'AppPage',
  props: ['appStyle'],
  components: {},
  setup() {
    return {}
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters([])
  }
});
