
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import AppPage from "@/components/AppPage.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'ForgotPassword',
  components: {AppPage},
  setup() {
    const router = useRouter();
    return {router}
  },
  data() {
    return {
      success: false,
      email: '',
      errors: [],
      isButtonLoading: false
    }
  },
  methods: {
    ...mapActions(['resetPasswordEmail']),
    back(){
      this.router.back();
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validate(){
      this.errors = [];

      if(this.email === ''){
        this.errors.push({error: 'email'})
      }
      if(!this.validateEmail(this.email)){
        this.errors.push({error: 'email', message: this.$t('form_error.bad_email')})
      }

      if(this.errors.length > 0){
        return false;
      }
      return true;
    },

    reset(){
      if(!this.validate()){
        return false;
      }

      const returnUrl = location.origin + '/auth/login';
      this.resetPasswordEmail({email: this.email, return_url: returnUrl})
          .then(() => {
            this.success = this.$t('forgotPassword.success');
          })
          .catch((error) => {
            this.parseErrors(error);
          })
    },
    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
    }

  },
  computed: {
    ...mapGetters(['theme'])
  }
});
