
import {defineComponent} from "vue";
import {ModalsContainer} from "vue-final-modal";

export default defineComponent({
  name: 'App',
  components: {ModalsContainer},
  mounted(){

    const query = new URLSearchParams(window.location.search);
    if(query.get('lang')){
      localStorage.setItem('currentLang', query.get('lang'))
      this.$i18n.locale = query.get('lang');
    }
    else if(localStorage.getItem('currentLang')){
      this.$i18n.locale = localStorage.getItem('currentLang')
    }
  }
});
