import axios from 'axios'
import config from "@/config/config";

const instance = axios.create({
  baseURL: config.baseUrl,
  timeout: 10000
});

instance.interceptors.response.use((response) => {
        return response;
    },
    async(error) => {
        return Promise.reject(error);
    });

if (localStorage.getItem('bearer') !== null) {
    instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('bearer')}`;
}

export default instance
