export default {
  booking: {
    title: "Per prenotare un tavolo",
    meta: "Prenotazione tavolo a {merchant}"
  },
  alreadyBook: {
    title: "Hai già una prenotazione in corso",
  },
  page:{
    madwith: "Fatto con",
    at: "a Nizza"
  },
  bookingDate: {
    label: 'Data di prenotazione',
    today: "Oggi",
    tomorrow: "Domani",
    other: "Altra data",
    choose_date: "Seleziona una data"
  },
  bookingNumber: {
    label: 'Numero di persone',
    quantity: '1 persona|{count} persone'
  },
  bookingSpot: {
    label: 'Scelta della camera',
    all: 'Tutto mi si addice'
  },
  bookingSchedule: {
    label: 'Ogni ora'
  },
  forgotPassword: {
    title: 'Hai dimenticato la password',
    cta: 'Reimposta la mia password',
    success: "È stata inviata un'e-mail per reimpostare la password."
  },
  cta: {
    book: 'Prenotare',
    save: "Registrare",
    login: "Accesso",
    update: "Modificare",
    register: "iscrizione",
    choose: "una scelta",
    cancel: "Annulla",
    cancel_booking: "Annulla la prenotazione",
    continue: "Continua",
    validate_booking: "Conferma la prenotazione"
  },
  form: {
    your_firstname: 'Il tuo nome',
    your_lastname: 'Il tuo nome',
    phone: "Telefono",
    phone_required: "*obbligatorio contattarti in caso di problemi",
    email: "Indirizzo email",
    password: "Parola d'ordine"
  },
  no_auth: {
    description: "Abbiamo bisogno dei tuoi dati di contatto per contattarti in caso di problemi",
    login_with_facebook: "Continua con Facebook",
    login_with_google: "Continua con Google",
    login_with_platform: "Ho un account Delicity",
    login_without_account: "Continua senza un account",
  },
  login: {
    title: "Il tuo conto Delizia",
    forgot_password: "Hai dimenticato la password",
    create_platform_account: "Crea un account Délicity"
  },
  register: {
    title: "Creare un account"
  },
  onboarding: {
    title: 'I tuoi dati di contatto',
    email_used_for_invoice: "Utilizzato per inviarti la fattura",
    email_used_for_summary: "Una conferma ti sarà inviata via SMS dopo la convalida."
  },
  no_account: {
    title: "I tuoi dati di contatto",
    receive_invoice: "Vorrei ricevere la mia fattura via e-mail",
    receive_confirm: "Vorrei ricevere una conferma via e-mail",
    email_necessary: "Obbligatorio se si desidera ricevere la fattura via e-mail"
  },
  not_found: {
    title: "Questa pagina non esiste."
  },
  booking_complete: {
    title: "La tua prenotazione è stata inviata al ristorante!"
  },
  booking_validation: {
    no_email: "Nessuna e-mail",
    title: 'Per convalidare',
    condition_1: 'Accettali',
    condition_2: 'Condizioni di vendita',
    condition_3: 'di {merchant} e Delizie',
    validate: "Conferma prenotazione",
    add_comment: "Aggiungi un commento",
    update_comment: "Aggiorna il commento",
    comment: "Commento : {comment}",
    your_booking: "La tua prenotazione",
    your_infos: "I tuoi dati di contatto",
    date: "dalle {date} alle {hour}",
    persons: "{count} persona · {spot}| {count} persone · {spot}",
    persons_no_zone: "una persona| {count} persone",
    comment_placeholder: "Aggiungi una nota"
  },
  calendar: {
    title: 'Scegli una data',
    validate: 'Scegli il {date}',
    closed: 'Chiuso',
  },
  form_error: {
    bad_password: "La password è sbagliata o l'account non esiste.",
    empty_phone: "Abbiamo bisogno di un numero di telefono per contattarti in caso di problemi.",
    bad_email: "E-mail non valido"
  },
  error: {
    field_required_for_saving: "Questo campo è obbligatorio per salvare.",
    max_clients_per_booking_exceeded: "Numero massimo di clienti superato per questa prenotazione.",
    last_minute_booking_not_allowed: "Le prenotazioni last minute non sono possibili in questo ristorante.",
    booking_not_activated: "La prenotazione non è ancora attivata per questo ristorante.",
    booking_redirect: "Verrai reindirizzato in pochi secondi...",
    booking_setting_not_found: "La prenotazione non è ancora attivata per questo ristorante.",
    merchant_not_found: "Questo ristorante non esiste.",
    booking_zone_not_exist: "L'area prenotazioni non esiste o non esiste più.",
  },
  bookingTracking: {
    booking_at: "{date} alle {hour}",
    add_calendar: "Aggiungi al calendario",
    share_booking: "Condividi la prenotazione",
    update_booking: "Modifica prenotazione",
    problem: "Problema con la prenotazione",
    how_its_work: "Funzionamento",
    how_its_work_text: "Riceverai un SMS non appena la tua prenotazione sarà convalidata. Il ristoratore si impegna a mantenere un tavolo fino a {duration} minuti dopo l'orario previsto.",
    route: 'itinerario',
    merchant_currently_checking: "Il ristorante ne verifica la disponibilità e confermerà la prenotazione",
    merchant_currently_unavailable: "Il ristorante non ha ancora convalidato la tua prenotazione. Puoi provare ad allegarlo {phone}.",
    current_status: "Stato attuale",
    ref: "riferimento"
  },
  status: {
    "10": "Per confermare",
    "20": "Confermato",
    "25": "Rifiutato",
    "30": "Rifiutato",
    "40": "Annullato",
  },
  bookingUpdate: {
    title: "Modifica prenotazione",
  },
  bookingCancel: {
    other: "Il ristorante ha rifiutato la tua prenotazione.",
    closed: 'Il ristorante sarà chiuso.',
    full: 'Il ristorante è pieno',
    client_cancel: 'Hai cancellato la prenotazione.',
    merchant_message: 'Messaggio dal ristorante: {message}',
    booking_canceled: "La tua prenotazione è stata cancellata.",
  },
  days:{
    1: "Lunedì",
    2: "Martedì",
    3: "Mercoledì",
    4: "Giovedì",
    5: "Venerdì",
    6: "Sabato",
    7: "Domenica"
  }
}
