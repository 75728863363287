import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border-t" }
const _hoisted_2 = { class: "text-gray-900" }
const _hoisted_3 = {
  key: 0,
  class: "mt-3 w-full flex flex-wrap gap-1"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["py-4 px-5 overflow-hidden", {'opacity-30': _ctx.disabled}])
    }, [
      _createElementVNode("div", {
        class: "flex justify-between items-center h-8",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setQuantity(null)))
      }, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('bookingNumber.label')), 1),
        _withDirectives(_createElementVNode("button", {
          class: "btn-action btn-action-active",
          style: _normalizeStyle({'background-color': _ctx.theme.primary, 'border-color': _ctx.theme.primary}),
          id: "booking-number-value"
        }, _toDisplayString(_ctx.$t('bookingNumber.quantity', {count: this.selected})), 5), [
          [_vShow, _ctx.selected]
        ]),
        _withDirectives(_createElementVNode("button", {
          class: "btn-action bg-gray-200 disabled:opacity-100 border-0 cursor-default",
          disabled: ""
        }, _toDisplayString(_ctx.$t('cta.choose')), 513), [
          [_vShow, !_ctx.selected && _ctx.disabled]
        ])
      ]),
      (!_ctx.disabled && !_ctx.selected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingMerchant.settings.maxClientsPerBookings, (i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                (_ctx.bookingMerchant.settings.minClientsPerBookings<=i)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "btn-action-round h-[50px] w-[50px]",
                      onClick: ($event: any) => (_ctx.setQuantity(i))
                    }, _toDisplayString(i), 9, _hoisted_4))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}