export default {
  booking: {
    title: "Réserver une table",
    meta: "Réservation de table {merchant}"
  },
  page:{
    madewith: "Fait avec",
    at: "à Nice"
  },
  alreadyBook: {
    title: "Vous avez déjà une réservation en cours",
  },
  bookingDate: {
    label: 'Date de la réservation',
    today: "Aujourd'hui",
    tomorrow: "Demain",
    other: "Autre date",
    choose_date: "Choisir une date"
  },
  bookingNumber: {
    label: 'Nombre de personnes',
    quantity: '1 personne|{count} personnes'
  },
  bookingSpot: {
    label: 'Choix de la salle',
    all: 'Tout me va'
  },
  bookingSchedule: {
    label: 'Horaire'
  },
  forgotPassword: {
    title: 'Mot de passe oublié',
    cta: 'Réinitialiser mon mot de passe',
    success: "Un email à été envoyé pour réinitialiser votre mot de passe."
  },
  cta: {
    book: 'Réserver',
    save: "Enregistrer",
    login: "Connexion",
    update: "Modifier",
    register: "Inscription",
    choose: "Au choix",
    cancel: "Annuler",
    cancel_booking: "Annuler la réservation",
    continue: "Continuer",
    validate_booking: "Valider la réservation"
  },
  form: {
    your_firstname: 'Votre Prénom',
    your_lastname: 'Votre nom',
    phone: "Téléphone",
    phone_required: "*obligatoire pour vous contacter en cas de problème",
    email: "Adresse email",
    password: "Mot de passe"
  },
  no_auth: {
    description: "Nous avons besoin de vos coordonnées pour vous contacter en cas de problème",
    login_with_facebook: "Continuer avec Facebook",
    login_with_google: "Continuer avec Google",
    login_with_platform: "J’ai un compte Delicity",
    login_without_account: "Continuer sans compte",
  },
  login: {
    title: "Votre Compte Delicity",
    forgot_password: "Mot de passe oublié",
    create_platform_account: "Créer un compte Délicity"
  },
  register: {
    title: "Créer un compte"
  },
  onboarding: {
    title: 'Vos Coordonnées',
    email_used_for_invoice: "Email utilisé pour vous envoyer votre facture",
    email_used_for_summary: "Une confirmation vous sera envoyée par SMS après validation"
  },
  no_account: {
    title: "Vos Coordonnées",
    receive_invoice: "Je souhaite recevoir ma facture par email",
    receive_confirm: "Je souhaite recevoir une confirmation par email",
    email_necessary: "Nécessaire si vous souhaitez recevoir la facture par email"
  },
  not_found: {
    title: "Cette page n'existe pas"
  },
  booking_complete: {
    title: "Votre réservation à bien été envoyé au restaurant !"
  },
  booking_validation: {
    no_email: "Pas d'email",
    title: 'À Valider',
    condition_1: 'J\'accepte les',
    condition_2: 'conditions générales',
    condition_3: 'de {merchant} et de Delicity',
    validate: "Valider la réservation",
    add_comment: "Ajouter un commentaire",
    update_comment: "Modifier le commentaire",
    comment: "Commentaire : {comment}",
    your_booking: "Votre réservation",
    your_infos: "Vos coordonnées",
    date: "{date} à {hour}",
    persons: "{count} personne · {spot}| {count} personnes · {spot}",
    persons_no_zone: "une personne| {count} personnes",
    comment_placeholder: "Ajouter une précision..."
  },
  calendar: {
    title: 'Choisissez une date',
    validate: 'Choisir le {date}',
    closed: "Fermé"
  },
  form_error: {
    bad_password: "Le mot de passe est erroné ou le compte n'existe pas.",
    empty_phone: "Nous avons besoin d'un numéro de téléphone pour vous joindre en cas de problème.",
    bad_email: "Email non valide"
  },
  error: {
    field_required_for_saving: "Ce champ est obligatoire pour enregistrer.",
    error_min_seats: "Le restaurant empêche de passer des réservations de moins de {min} places.",
    error_max_seats: "Le restaurant empêche de passer des réservations de plus de {max} places.",
    user_booking_limit_reached: "Vous avez déjà effectué une réservation à cette date dans les même horaires. Vous ne pouvez faire qu'une reservation à la fois.",
    no_seats_available: "Il n'y a plus de place disponible pour cette date et horaire.",
    max_clients_per_booking_exceeded: "Nombre de client maximum dépassé pour cette réservation.",
    last_minute_booking_not_allowed: "La réservation à la dernière minute n'est pas possible sur ce restaurant.",
    booking_not_activated: "La réservation en ligne n'est pas encore activée pour ce restaurant.",
    booking_redirect: "Vous allez être redirigé dans quelques secondes...",
    booking_setting_not_found: "La réservation n'est pas encore activée pour ce restaurant.",
    merchant_not_found: "Ce restaurant n'existe pas.",
    booking_zone_not_exist: "La zone de réservation n'existe pas ou plus.",
    booking_call_for_booking: "Pour réserver, vous pouvez joindre le restaurant au {phone}",
  },
  bookingTracking: {
    update: "Modifier la réservation",
    title: "Votre réservation",
    booking_at: "{date} à {hour}",
    add_calendar: "Ajouter au calendrier",
    share_booking: "Partager la réservation",
    update_booking: "Modifier la réservation",
    problem: "Problème avec la réservation",
    how_its_work: "Fonctionnement",
    how_its_work_text: "Vous recevrez un SMS dès la validation de votre réservation. Le restaurateur s’engage à garder une table jusqu’à {duration} min après l’heure prévue.",
    merchant_currently_checking: "{merchant} a reçu votre demande de réservation, vous recevrez un SMS de confirmation dans les meilleurs délais.",
    route: 'Itinéraire',
    merchant_currently_unavailable: "Le restaurant n'a toujours pas validé votre réservation. Vous pouvez essayer de le joindre au {phone}.",
    current_status: "Statut actuel",
    ref: "Référence"
  },
  status: {
    "10": "Envoyée",
    "20": "Confirmée",
    "25": "Refusée",
    "30": "Finalisée",
    "40": "Annulée",
  },
  bookingUpdate: {
    title: "Modifier la réservation",
  },
  bookingCancel: {
    other: 'Le restaurant a annulé votre réservation.',
    closed: 'Le restaurant sera fermé.',
    full: 'Le restaurant est complet',
    merchant_client_cancel: 'Le restaurant à annulé votre réservation.',
    client_cancel: 'Vous avez annulé la réservation.',
    merchant_message: 'Message du restaurant : {message}',
    booking_canceled: "Votre réservation a été annulée.",
  },
  days: {
    1: "Lundi",
    2: "Mardi",
    3: "Mercredi",
    4: "Jeudi",
    5: "Vendredi",
    6: "Samedi",
    7: "Dimanche"
  },
  modalCancel: {
    title: "Annuler la réservation",
    message: "Êtes-vous sûr de vouloir annuler votre réservation ?",
    close: "Fermer",
    confirm: "J'annule ma réservation"
  }
}
