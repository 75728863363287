
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import MerchantFooter from '@/components/MerchantFooter.vue';
import BookingDate from "@/components/Booking/BookingDate.vue";
import BookingNumber from "@/components/Booking/BookingNumber.vue";
import BookingSpot from "@/components/Booking/BookingSpot.vue";
import BookingSchedule from "@/components/Booking/BookingSchedule.vue";
import AppPage from "@/components/AppPage.vue";
import {useRouter} from "vue-router";
import AppLoader from "@/components/AppLoader.vue";
import {useMeta} from "vue-meta";
import LangSelector from "@/components/LangSelector.vue";
import ConfirmCancel from "@/components/Modals/ConfirmCancel.vue";
import {useModal} from "vue-final-modal";

export default defineComponent({
  name: 'Booking',
  components: {BookingDate, BookingNumber, BookingSpot, BookingSchedule, AppPage, AppLoader, MerchantFooter, LangSelector},
  setup() {
    const router = useRouter();
    const confirmCancel = useModal({component: ConfirmCancel});
    let hasShare;
    if(navigator.share){
      hasShare = true;
    }else{
      hasShare = false;
    }
    return {router, hasShare, confirmCancel}
  },
  data() {
    return {
      date: null,
      quantity: null,
      spot: null,
      schedule: null,
      isLoading: true,
      isButtonLoading: false,
      error: false
    }
  },
  methods: {
    ...mapActions(['getBookingMerchantInfos', 'getBookingDaySlots', 'createBooking', 'initAuth', 'getBooking', 'getBookingZones', 'getBookingCloses']),
    async validateBooking(){
      this.isButtonLoading = true;

      await this.createBooking({dateTime: this.schedule, zoneId: this.spot, quantity:this.quantity, token:this.$route.query.token})
          .then(() => {
            if(this.isAuth){
              if(this.booking && this.booking.status){
                this.router.push('/'+ this.bookingMerchantSlug +'/booking_complete/'+ this.bookingToken);
              }
              else{
                this.router.push('/'+ this.bookingMerchantSlug +'/validate/'+ this.bookingToken);
              }
            }
            else{
              this.router.push({name: 'NoAuth'});
            }
          })
          .catch((error) => {
            this.isButtonLoading = false;
            if(this.$te('error.'+error)){
              this.error = this.$t('error.'+error);
            }
          });
    },
    cancelBooking(){
      this.confirmCancel.open();
    },
    selectLang(lang){
      this.$i18n.locale = lang;
      localStorage.setItem('currentLang', lang);
    },
    getSlots(){
      if(this.schedule !== null){
        this.schedule = null;
      }
      if(this.spot && this.date && this.quantity){
        this.getBookingDaySlots({date: this.date, bookingZoneId: this.spot, seats: this.quantity});
      }
    },
    share(){
      if (navigator.share) {
        navigator.share({
          text: 'Réserver une table en ligne chez ' + this.bookingMerchant.name,
          url: location.href
        }).catch(console.error);
      }
    },
  },
  watch:{
    date(date){
      this.getSlots();
      this.getBookingZones({date: date})
          .then((data) => {
            if(data.bookingZones.length === 1){
              this.spot = data.bookingZones[0].id;
            }
          });
    },
    spot(){
      this.getSlots();
    },
    quantity(){
      this.getSlots();
    }
  },
  computed: {
    ...mapGetters(['bookingMainError', 'isAuth', 'bookingMerchantSlug', 'bookingToken', 'booking', 'bookingMerchant', 'theme', 'currentBookingToken']),
    enableNumber(){
      return this.date;
    },
    enableSpot(){
      return this.enableNumber && this.quantity;
    },
    enableSchedule(){
      return this.enableSpot && this.spot !== null;
    },
    isBookingPossible(){
      return this.date && this.quantity && this.spot && this.schedule;
    },
    backUrl(){
      return document.referrer !== ''  ? document.referrer : this.bookingMerchant.url;
    }
  },
  async mounted(){
    useMeta({
      title: this.$t('booking.meta')
    });

    this.$store.commit('SET_CURRENT_MERCHANT', this.$route.params.merchantSlug);

    this.isLoading = true;
    await this.getBookingMerchantInfos()
    .catch(() => {
      this.isLoading = false;
    });

    await this.initAuth();
    //this.getBookingCloses({slug: this.$route.params.merchantSlug})


    if(this.booking && (this.booking.status === 40 || this.booking.status === 30 || this.booking.status === 25)){
      this.$store.commit('SET_BOOKING', false);
    }

    if(this.$route.query.token){
      await this.getBooking({token: this.$route.query.token, merchant: this.$route.params.merchantSlug})
          .then((booking) => {
            if(this.booking && (this.booking.status === 40 || this.booking.status === 30 || this.booking.status === 25)){
              this.$store.commit('SET_BOOKING', false);
              this.router.push({name: 'BookingHome', params: {merchantSlug: this.bookingMerchantSlug}});
              return;
            }
            this.date = booking.bookingForTimestampDay;
            this.quantity = booking.clientCount;
            this.spot = booking.bookingZone.id;
            this.$nextTick(() => {
              this.schedule = booking.bookingForTimestamp;
            });
          })
          .catch((error) => {
            console.log('error', error)
          });
    }
    else if(this.booking){
      this.date = this.booking.bookingForTimestampDay;
      this.quantity = this.booking.clientCount;
      this.spot = this.booking.bookingZone.id ?? 'all';
      this.$nextTick(() => {
        this.schedule = this.booking.bookingForTimestamp;
      });
    }

    this.isLoading = false;
  }
});
