export default {
  booking: {
    title: "Book a table",
    meta: "Book a table in {merchant}"
  },
  alreadyBook: {
    title: "You already have a current reservation",
  },
  page:{
    madewith: "Made with",
    at: "at nice"
  },
  bookingDate: {
    label: 'Date of reservation',
    today: "Today",
    tomorrow: "Tomorrow",
    other: "Other date",
    choose_date: "Choose a date"
  },
  bookingNumber: {
    label: 'Number of persons',
    quantity: '1 person|{count} persons'
  },
  bookingSpot: {
    label: 'Choice of room',
    all: 'Everything suits me'
  },
  bookingSchedule: {
    label: 'Schedule'
  },
  forgotPassword: {
    title: 'Forgot your password ?',
    cta: 'Reset my password',
    success: "An email has been sent to reset your password."
  },
  cta: {
    book: 'Book',
    save: "Save",
    login: "Login",
    update: "Update",
    register: "Register",
    choose: "A choice",
    cancel: "Cancel",
    cancel_booking: "Cancel the reservation",
    continue: "Continue",
    validate_booking: "Validate your booking"
  },
  form: {
    your_firstname: 'Your firstname',
    your_lastname: 'Your lastname',
    phone: "Phone",
    phone_required: "*mandatory to contact you in case of problem",
    email: "Email address",
    password: "Password"
  },
  no_auth: {
    description: "We need your contact details to contact you in case of a problem",
    login_with_facebook: "Continue with Facebook",
    login_with_google: "Continue with Google",
    login_with_platform: "I have a Delicity account",
    login_without_account: "Continue without an account",
  },
  login: {
    title: "Your Delicity Account",
    forgot_password: "Forgot your password",
    create_platform_account: "Create a Délicity account"
  },
  register: {
    title: "Create an account"
  },
  onboarding: {
    title: "Your contact details",
    email_used_for_invoice: "Used to send you your invoice",
    email_used_for_summary: "A confirmation will be sent to you by SMS after validation."
  },
  no_account: {
    title: "Your contact details",
    receive_invoice: "I would like to receive my invoice by email",
    receive_confirm: "I would like to receive a confirmation by email",
    email_necessary: "Required if you want to receive the invoice by email"
  },
  not_found: {
    title: "This page does not exist."
  },
  booking_complete: {
    title: "Your reservation has been sent to the restaurant!"
  },
  booking_validation: {
    no_email: "No email",
    title: 'To be validate',
    condition_1: 'I Accept the',
    condition_2: 'Terms of Sales',
    condition_3: 'of {merchant} and of Delicity',
    validate: "Confirm reservation",
    add_comment: "Add a comment",
    update_comment: "Update comment",
    comment: "Comment : {comment}",
    your_booking: "Your reservation",
    your_infos: "Your contact details",
    date: "{date} at {hour}",
    persons: "{count} person · {spot}| {count} persons · {spot}",
    persons_no_zone: "one person| {count} persons",
    comment_placeholder: "Add a note..."
  },
  calendar: {
    title: 'Choose a date',
    validate: 'Select {date}',
    closed: 'Closed',
  },
  form_error: {
    bad_password: "The password is wrong or the account does not exist.",
    empty_phone: "We need a phone number to reach you if there is a problem.",
    bad_email: "Invalid email"
  },
  error: {
    field_required_for_saving: "This field is required.",
    max_clients_per_booking_exceeded: "Maximum number of customers exceeded for this reservation.",
    last_minute_booking_not_allowed: "Last minute reservations are not possible at this restaurant.",
    booking_not_activated: "The reservation is not yet activated for this restaurant.",
    booking_redirect: "You will be redirected in a few seconds...",
    booking_setting_not_found: "The reservation is not yet activated for this restaurant.",
    merchant_not_found: "This restaurant does not exist.",
    booking_zone_not_exist: "The booking area does not exist or no longer exists.",
  },
  bookingTracking: {
    update: "Update your booking",
    title: "Your booking",
    booking_at: "{date} at {hour}",
    add_calendar: "Add to calendar",
    share_booking: "Share my booking",
    update_booking: "Update my booking",
    problem: "Problem with booking",
    how_its_work: "How it works ?",
    how_its_work_text: "You will receive an SMS as soon as your reservation is confirmed. The restaurateur agrees to keep a table until {duration} minutes after the scheduled time.",
    route: 'Route',
    merchant_currently_checking: "The restaurant checks its availability and will confirm your reservation",
    merchant_currently_unavailable: "The restaurant has still not validated your reservation. You can try to attach it to {phone}.",
    current_status: "Actual status",
    ref: "reference"
  },
  status: {
    "10": "Sent",
    "20": "Confirmed",
    "25": "Refused",
    "30": "Ended",
    "40": "Canceled",
  },
  bookingUpdate: {
    title: "Modify booking",
  },
  bookingCancel: {
    other: "The restaurant has canceled your reservation.",
    closed: 'The restaurant will be closed.',
    full: 'The restaurant is full',
    client_cancel: 'You have canceled the reservation.',
    merchant_message: 'Message from the restaurant: {message}',
    booking_canceled: "Your reservation has been cancelled.",
  },
  days: {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday"
  }
}
