
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import AppPage from "@/components/AppPage.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'Login',
  components: {AppPage},
  setup() {
    const router = useRouter();
    return {router}
  },
  data() {
    return {
      email: '',
      password: '',
      errors: [],
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['logWithPlatform']),
    back(){
      this.router.back();
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    validate(){
      this.errors = [];
      if(this.email === '' || !this.validateEmail(this.email)){
        this.errors.push({error: 'email'})
      }
      if(this.password === ''){
        this.errors.push({error: 'password'})
      }

      if(this.errors.length > 0){
        return false;
      }
      return true;
    },
    async save(){
      if(!this.validate()){
        return;
      }

      this.isLoading = true;
      await this.logWithPlatform({email: this.email, password: this.password})
          .then(() => {
            const merchant = localStorage.getItem('current_merchant');
            const token = localStorage.getItem('token_'+merchant);
            this.router.push({name: 'Validate', params: {merchantSlug: merchant, token: token}})
          })
          .catch((error) => {
            if(error === 'invalid_grant'){
              this.errors.push({error: 'password', message: this.$t('form_error.bad_password')})
            }
            this.isLoading = false;
          });

    }
  },
  computed: {
    ...mapGetters(['theme'])
  }
});
