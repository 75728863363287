
import ValidateButton from '@/components/ValidateButton.vue';
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import AppPage from "@/components/AppPage.vue";
import {useRouter} from "vue-router";
import AppLoader from "@/components/AppLoader.vue";
import BookingComment from "@/components/Modals/BookingComment.vue";
import LangSelector from "@/components/LangSelector.vue";
import {useModal, VueFinalModal} from "vue-final-modal";

export default defineComponent({
  name: 'Validate',
  components: {AppPage, AppLoader, LangSelector, ValidateButton},
  setup() {
    const router = useRouter();
    const commentModal = useModal({component: BookingComment});
    return {router, commentModal}
  },
  data() {
    return {
      isLoading: true,
      isButtonLoading: false,
      isConditionValidated: false,
      isSmallValidateButtonDisplayed: false,
      errors: [],
      showComment: false,
      comment: null,
      error: null,
      mainValidateButtonOffsetTop: 0
    }
  },
  methods: {
    ...mapActions(['initAuth', 'updateBookingUser', 'validateBookingAction', 'setCommentBooking', 'setCommentBooking']),
    showBookingComment(){
      this.commentModal.open();
    },
    async validateBooking(){
      this.errors = [];
      if(!this.isConditionValidated){
        this.errors.push({error: 'condition'});
        document.getElementById('main').scrollTo({
          top: document.getElementById('condition').offsetTop,
          left: 0,
          behavior: 'smooth'
        });
        return;
      }

      this.isButtonLoading = true;
      this.validateBookingAction({token: this.bookingToken, merchant: this.bookingMerchantSlug})
          .then((response) => {
            this.router.push({name: 'BookingComplete', params: {merchantSlug: this.bookingMerchantSlug, token: this.bookingToken}});
            this.isButtonLoading = false;
          })
          .catch((error) => {
            if(error === 'already_done'){
              this.router.push({name: 'BookingComplete', params: {merchantSlug: this.bookingMerchantSlug, token: this.bookingToken}});
            }
            if(error === 'canceled'){
              this.router.push({name: 'BookingHome', params: {merchantSlug: this.bookingMerchantSlug}});
            }
            if(error === 'user_booking_limit_reached'){
              document.getElementById('main').scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.error = this.$t('error.user_booking_limit_reached');
            }
            if(error === 'no_seats_available'){
              document.getElementById('main').scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.error = this.$t('error.no_seats_available');
            }
            if(error === 'error_min_seats'){
              document.getElementById('main').scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.error = this.$t('error.error_min_seats', {min: this.bookingMerchant.settings.minClientsPerBookings});
            }
            if(error === 'error_max_seats'){
              document.getElementById('main').scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.error = this.$t('error.error_max_seats', {max: this.bookingMerchant.settings.maxClientsPerBookings});
            }
            this.isButtonLoading = false;
            if(error.response && error.response.status === 401){
              localStorage.removeItem('bearer');
              localStorage.removeItem('refresh_token');
              this.$store.commit('SET_AUTH_STATUS', false);
              this.router.push({name: 'NoAuth'});
            }
          });

    },
    updateBooking(){
      this.router.push({name: 'BookingHome', params: {merchantSlug: this.bookingMerchantSlug}, query: {token: this.bookingToken}})
    },
    updateAccount(){
      this.router.push({name: 'Onboarding'})
    },
    selectLang(lang){
      this.$i18n.locale = lang;
      localStorage.setItem('currentLang', lang);
    }
  },
  computed: {
    ...mapGetters(['isAuth', 'booking', 'bookingToken', 'bookingMerchantSlug', 'theme', 'bookingMerchant', 'currentBookingToken'])
  },
  watch: {
    isConditionValidated(newVal){
      if(newVal){
        this.errors = this.errors.filter(e => e.error !== 'condition')
      }
    }
  },
  async mounted(){
    this.isLoading = true;
    await this.initAuth();
    this.$store.commit('SET_BOOKING_TOKEN', this.$route.params.token);
    this.$store.commit('SET_CURRENT_MERCHANT', this.$route.params.merchantSlug);
    await this.updateBookingUser()
        .catch((error) => {
          if(error === 'already_done'){
            this.router.push({name: 'BookingComplete', params: {merchantSlug: this.bookingMerchantSlug, token: this.$route.params.token}});
          }
          if(error === 'canceled'){
            this.router.push({name: 'BookingHome', params: {merchantSlug: this.bookingMerchantSlug}});
          }
          if(error === 'no_phone'){
            this.router.push({name: 'Onboarding', params: {merchantSlug: this.bookingMerchantSlug}});
          }
          if(error.response && error.response.status === 401){
            localStorage.removeItem('bearer');
            localStorage.removeItem('refresh_token');
            this.$store.commit('SET_AUTH_STATUS', false);
            this.router.push({name: 'NoAuth'});
          }
        });
    this.isLoading = false;

    this.$nextTick(() => {
      const button = document.getElementById('main-validate-button');
      this.mainValidateButtonOffsetTop = button.offsetTop;
      const el = document.getElementById('main');

      this.isSmallValidateButtonDisplayed = this.mainValidateButtonOffsetTop >= el.scrollTop + el.offsetHeight - (button.offsetHeight / 2);
      el.addEventListener("scroll", (e) => {
        this.isSmallValidateButtonDisplayed = this.mainValidateButtonOffsetTop >= el.scrollTop + el.offsetHeight - (button.offsetHeight / 2);
      });

    });


  }
});
