import moment from "moment";
import axios from "./axios-instance";

// Get real server time
axios.get('/api/server_time')
    .then((response) => {
        const offset = moment.unix(response.data.time).valueOf() - Date.now();
        moment.now = function() {
            return Date.now() + offset;
        }
    })

moment.locale("fr");

export default moment;
