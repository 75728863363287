import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border-t" }
const _hoisted_2 = {
  key: 0,
  class: "mt-3 gap-2 grid grid-cols-4 md:grid-cols-6"
}
const _hoisted_3 = ["onClick", "disabled"]
const _hoisted_4 = {
  key: 1,
  class: "flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_loader = _resolveComponent("app-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["py-4 px-5 overflow-hidden", {'opacity-30': _ctx.disabled}])
    }, [
      _createElementVNode("div", {
        class: "flex justify-between items-center h-8",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectSchedule(null)))
      }, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('bookingSchedule.label')), 1),
        _withDirectives(_createElementVNode("button", {
          class: "btn-action btn-action-active",
          style: _normalizeStyle({'background-color': _ctx.theme.primary, 'border-color': _ctx.theme.primary}),
          id: "booking-schedule-value"
        }, _toDisplayString(_ctx.currentSelected), 5), [
          [_vShow, _ctx.currentSelected]
        ]),
        _withDirectives(_createElementVNode("button", {
          class: "btn-action bg-gray-200 disabled:opacity-100 border-0 cursor-default",
          disabled: ""
        }, _toDisplayString(_ctx.$t('cta.choose')), 513), [
          [_vShow, !_ctx.selected && _ctx.disabled]
        ])
      ]),
      (!_ctx.disabled && !_ctx.selected && _ctx.bookingSlots.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingSlots, (slot, i) => {
              return (_openBlock(), _createElementBlock("button", {
                class: "btn-action mb-1",
                key: i,
                onClick: ($event: any) => (_ctx.selectSchedule(slot.timestamp)),
                disabled: !slot.available
              }, _toDisplayString(slot.hour), 9, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.disabled && !_ctx.selected && _ctx.bookingSlots.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_app_loader, { class: "text-gray-400" })
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}