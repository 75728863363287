<template>
    <vue-final-modal
      v-bind="$attrs"
      content-class="flex justify-center items-center h-full">
      <div class="flex justify-between flex-col overflow-hidden items-center relative max-h-full rounded-[25px] bg-white w-[80vw] sm:w-[350px]">
        <div class="flex w-full justify-end items-center p-4">
          <button @click="close"><i class="material-icons text-3xl">close</i></button>
        </div>

        <div class="flex flex-col items-center justify-center pt-0 p-10 text-center">
          <i class="material-icons text-[3rem] text-black mb-2">event_busy</i>
          <strong class="text-xl mb-2">{{$t('modalCancel.title')}}</strong>
          <span>{{$t('bookingTracking.booking_at', {date: booking.bookingForLong, hour: booking.bookingForHour})}}</span>
          <span>#{{booking.ref}} • {{$t('booking_validation.persons_no_zone', {count: booking.clientCount})}}</span>
        </div>


        <div class="flex gap-3 w-full px-6 pb-6">
          <button @click="cancel" :disabled="isLoading" class="disabled:opacity-75 h-[50px] rounded-full flex-1 h-10 bg-gray-100 font-bold bg-red-600 text-white flex justify-center items-center">
            <span v-if="!isLoading">{{ $t('modalCancel.confirm') }}</span>
            <app-loader v-if="isLoading" class="text-white h-6"></app-loader>
          </button>
        </div>

      </div>

    </vue-final-modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {defineComponent} from "vue";
import {useRouter} from "vue-router";
import AppLoader from "@/components/AppLoader";
import {VueFinalModal, useVfm} from "vue-final-modal";

export default defineComponent({
  name: 'ConfirmCancel',
  setup(){
    const router = useRouter()
    const vfm = useVfm()
    return {router, vfm}
  },
  components: {AppLoader, VueFinalModal},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['cancelBookingAction']),
    cancel(){
      this.isLoading = true;
      this.cancelBookingAction({token: this.booking.token, slug: this.bookingMerchantSlug})
          .then(() => {
            this.isLoading = false;
            this.router.push('/'+ this.bookingMerchantSlug +'/booking_complete/'+ this.booking.token);
            this.$vfm.closeAll();
          })
    },
    close(){
      this.vfm.closeAll();
    }
  },
  computed: {
    ...mapGetters(['bookingMerchantSlug', 'booking'])
  },
  mounted() {
    this.isLoading = false;
  }
});
</script>
