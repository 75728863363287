import axios from '@/axios-instance';
import {i18n} from '@/lang/index'
import config from "@/config/config";

export default {
    state: {
        isAuth: false,
        enabledLoginMethodsLoading: false,
        enabledLoginMethods: {
            facebook: false,
            google: false,
            apple: true
        }
    },
    mutations: {
        SET_ENABLED_LOGIN_METHODS_LOADING(state, enabledLoginMethodsLoading){
            state.enabledLoginMethodsLoading = enabledLoginMethodsLoading;
        },
        SET_ENABLED_LOGIN_METHODS(state, enabledLoginMethods){
            state.enabledLoginMethods = enabledLoginMethods;
        },
        SET_AUTH_STATUS(state, isAuth){
            state.isAuth = isAuth;
        }
    },
    getters: {
        isAuth: state => state.isAuth,
        enabledLoginMethods: state => state.enabledLoginMethods,
        enabledLoginMethodsLoading: state => state.enabledLoginMethodsLoading,
    },
    actions: {
        getEnabledLoginMethods({commit, state}){
            commit('SET_ENABLED_LOGIN_METHODS', {
                facebook: false,
                google: false,
                apple: true
            })
            commit('SET_ENABLED_LOGIN_METHODS_LOADING', true)
            return new Promise(((resolve, reject) => {
                axios.post('api/mobile/login_methods')
                    .then((response) => {
                        commit('SET_ENABLED_LOGIN_METHODS', response.data.methods);
                        commit('SET_ENABLED_LOGIN_METHODS_LOADING', false)
                        resolve(response.data.methods);
                    })
                    .catch((error) => {
                        console.log('Request error', error);
                        commit('SET_ENABLED_LOGIN_METHODS_LOADING', false)
                        reject(true);
                    })
            }));
        },


        initAuth({commit}){
            if(localStorage.getItem('bearer')){
                commit('SET_AUTH_STATUS', true);
            }
            else{
                commit('SET_AUTH_STATUS', false);
            }
        },

        getAccessToken({commit}){
            return new Promise(((resolve, reject) => {
                axios.post('api/front/user/authCheck', {userToken: localStorage.getItem('userToken')})
                    .then((response) => {
                        if(response.data.success){
                            localStorage.setItem('bearer', response.data.bearer);
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.bearer}`;
                            commit('SET_AUTH_STATUS', true);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        getUserAction({commit}){
            return new Promise(((resolve, reject) => {
                axios.post('api/booking/user/get', {})
                    .then((response) => {
                        if (response.data.success) {

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        saveOnboardingAction({commit}, {firstname, lastname, phone, email}){
            return new Promise(((resolve, reject) => {
                axios.post('api/front/user/update', {firstname: firstname, lastname: lastname, email: email, phone:phone})
                    .then((response) => {
                        if (response.data.success) {

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data.errors);
                    })
            }));
        },

        logWithNoAccount({commit}, {firstname, lastname, phone, email}){
            const currentMerchant = localStorage.getItem('current_merchant');
            const currentToken = localStorage.getItem('token_' + currentMerchant);

            return new Promise(((resolve, reject) => {
                axios.post('api/front/user/no_account', {firstname: firstname, lastname: lastname, phone: phone, email: email, bookingToken:currentToken})
                    .then((response) => {
                        if (response.data.success) {
                            localStorage.setItem('bearer', response.data.access_token);
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
                            commit('SET_AUTH_STATUS', true);

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data.errors);
                    })
            }));
        },


        logWithPlatform({commit}, {email, password}){
            return new Promise(((resolve, reject) => {
                const payload = {
                    grant_type: 'password',
                    client_id: config.authClientId,
                    client_secret: config.authClientSecret,
                    username: email,
                    password: password,
                };
                axios.post('/oauth/token', payload)
                    .then((response) => {
                        if (response.data.access_token) {
                            localStorage.setItem('bearer', response.data.access_token);
                            localStorage.setItem('refresh_token', response.data.refresh_token);
                            axios.defaults.headers.common.Authorization = `Bearer ${ response.data.access_token}`;
                            commit('SET_AUTH_STATUS', true);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        commit('SET_AUTH_STATUS', false);
                        reject(error.response.data.error);
                    })
            }));

        },

        registerAction({commit}, {firstname, lastname, email, phone, password}){
            return new Promise(((resolve, reject) => {
                axios.post('api/front/user/register', {firstname:firstname, lastname:lastname, email:email, phone:phone, password:password})
                    .then((response) => {
                        if (response.data.success) {
                            localStorage.setItem('bearer', response.data.access_token);
                            axios.defaults.headers.common.Authorization = `Bearer ${ response.data.access_token}`;
                            commit('SET_AUTH_STATUS', true);

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data.errors);
                    })
            }));
        },


        resetPasswordEmail({commit, rootState}, {email, return_url}){
            return new Promise((resolve, reject) => {
                axios.post('api/user/forgot_password', {email:email, return_url:return_url})
                    .then((response) => {
                        if(response.data.success){
                            resolve(true);
                        }
                        else{
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data.errors);
                    })
            });
        }

    },
}
