
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import anime from "animejs/lib/anime.es.js";
import moment from '@/moment-instance'

export default defineComponent({
  name: 'Calendar',
  props: {
    modelValue:{
      default: null
    },
    max: {
      default: null,
      type: String
    },
    min: {
      default: null,
      type: String
    }
  },
  components: {},
  setup() {
    const randomId = (Math.random() + 1).toString(36).substring(7);
    return {randomId}
  },
  data() {
    return {
      date: null,
      isOpen: false,
      month: null
    }
  },
  methods: {
    ...mapActions([]),
    subMonth(){
      if(this.canSubMonth){
        this.month = moment.unix(this.month).subtract(1, 'month').unix();
      }
    },
    addMonth(){
      this.month = moment.unix(this.month).add(1, 'month').unix();
    },
    selectDay(date){
      if(this.isDisabled(date)){
        return false;
      }
      this.date = date;

    },
    isDisabled(day){
      if(this.bookingCloses.find(b => b === day.dayOfYear())){
        return true;
      }
      if(this.closedDays.find(c => c === day.isoWeekday())){
        return true;
      }
      if(!this.bookingMerchant.open.today && day.dayOfYear() === this.currentDayOfYear){
        return true;
      }
      if(!this.bookingMerchant.open.tomorrow && day.dayOfYear() === (this.currentDayOfYear+1)){
        return true;
      }

      return day.isBefore(moment(), 'day');
    },
    open(){
      console.log(this.$i18n.locale);
      moment.locale(this.$i18n.locale);

      this.isOpen = true;
      anime({
        targets: '#calendar-'+this.randomId,
        opacity: '100%',
        duration: 200
      });
      anime({
        targets: '#calendar-'+this.randomId + ' #calendar-content',
        translateY: -200,
        easing: 'easeInQuad',
        direction: 'reverse',
        duration: 200
      });
    },
    close(){
      this.isOpen = false;
    },
    backClick(e){
      let path = (e.composedPath && e.composedPath()) || e.path;
      if(path[0].id === 'calendar-'+this.randomId){
        this.close();
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    validate(){
      this.$emit('update:modelValue', this.date.unix());
      this.$emit('update', this.date.unix());
      this.close();
    }
  },
  computed: {
    ...mapGetters(['merchantShifts', 'bookingCloses', 'bookingMerchant']),
    currentMonth(){
      return this.capitalizeFirstLetter(moment.unix(this.month).format('MMMM YYYY'));
    },
    canSubMonth(){
      return !moment.unix(this.month).isSame(moment(), 'month');
    },
    currentDayOfYear(){
      return moment().dayOfYear();
    },
    currentMonthDays(){
      let days = [];
      for (let i = 0; i < moment.unix(this.month).daysInMonth(); i++) {
        days.push(moment.unix(this.month).add(i, 'days'));
      }
      return days;
    },
    prefixDays(){
      return Math.max(0, moment.unix(this.month).isoWeekday()-1);
    },
    suffixDays(){
      const diff = (moment.unix(this.month).endOf('month').isoWeekday());
      return 7-diff;
    },
    selectedDay(){
      if(!this.date){
        return null;
      }
      return this.date.format('ddd DD MMM YYYY')
    },
    closedDays(){
      if(!this.merchantShifts){
        return [];
      }
      return this.merchantShifts.filter(m => m.slots.length === 0).map(m => m.day);
    }
  },
  watch: {
    modelValue(val){
      this.date = val;
    },
    closedDays(){
      console.log(this.closedDays);
    }
  },
  mounted(){

    this.date = this.modelValue;
    this.month = moment().startOf('month').unix();
    //this.date = moment();
  }
});
