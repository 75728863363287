import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border-t" }
const _hoisted_2 = {
  key: 0,
  class: "mt-3 grid grid-cols-2 md:grid-cols-4 gap-3"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "bg-[#00000075] flex justify-center items-center text-center px-2" }
const _hoisted_5 = { class: "text-sm text-white font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["py-4 px-5 overflow-hidden", {'opacity-30': _ctx.disabled}])
    }, [
      _createElementVNode("div", {
        class: "flex justify-between items-center h-8",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectSpot(null)))
      }, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('bookingSpot.label')), 1),
        _withDirectives(_createElementVNode("button", {
          class: "btn-action btn-action-active",
          style: _normalizeStyle({'background-color': _ctx.theme.primary, 'border-color': _ctx.theme.primary}),
          id: "booking-spot-value"
        }, _toDisplayString(_ctx.selectedZone), 5), [
          [_vShow, _ctx.selected !== null]
        ]),
        _withDirectives(_createElementVNode("button", {
          class: "btn-action bg-gray-200 disabled:opacity-100 border-0 cursor-default",
          disabled: ""
        }, _toDisplayString(_ctx.$t('cta.choose')), 513), [
          [_vShow, !_ctx.selected && _ctx.disabled]
        ])
      ]),
      (!_ctx.disabled && _ctx.selected === null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingZones, (zone) => {
              return (_openBlock(), _createElementBlock("div", {
                onClick: ($event: any) => (_ctx.selectSpot(zone.id)),
                key: zone.id,
                class: "cursor-pointer aspect-h-1 aspect-w-2 bg-gray-100 rounded-lg overflow-hidden bg-cover",
                style: _normalizeStyle('background-image:url('+zone.image.path+');')
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(zone.name), 1)
                ])
              ], 12, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 512)), [
    [_vShow, _ctx.bookingZoneCount > 1]
  ])
}