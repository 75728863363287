
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import AppPage from "@/components/AppPage.vue";

export default defineComponent({
  name: 'NotFound',
  components: {AppPage},
  setup() {
    return {}
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters([])
  },
  mounted(){
    console.log('Error redirect not found');
    setTimeout(() => {
      location.href = 'https://delicity.com/'
    }, 3000);
  }
});
