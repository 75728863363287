import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex-1 overflow-y-auto",
  id: "main"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "fixed top-0 left-0 right-0 bottom-0 flex flex-col",
    style: _normalizeStyle(_ctx.appStyle)
  }, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("header", null, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("footer", null, [
      _renderSlot(_ctx.$slots, "footer")
    ])
  ], 4))
}