
import {mapActions, mapGetters} from "vuex";
import {defineComponent, ref} from "vue";
import anime from 'animejs/lib/anime.es.js';
import Calendar from "@/components/Calendar.vue";
import moment from "@/moment-instance";

export default defineComponent({
  name: 'BookingDate',
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    modelValue: {
      default: null
    }
  },
  components: {Calendar},
  setup() {
    const calendar = ref();
    return {calendar}
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    ...mapActions([]),
    openDate(){
      this.calendar.open();
    },
    setValue(value){
      let timestamp;
      if(value === 'tomorrow'){
        timestamp = moment().startOf('day').add(1, 'day').unix();
      }
      else if(value === 'today'){
        timestamp = moment().startOf('day').unix();
      }
      else if(Number.isInteger(value)){
        timestamp = value;
      }
      this.selected = timestamp;
      this.$emit('update:modelValue', this.selected);
      anime({
        targets: '#booking-date-value',
        translateY: 70,
        duration: 150,
        direction: 'reverse',
        easing: 'easeInCubic'
      });
    }
  },
  computed: {
    ...mapGetters(['bookingMerchant', 'theme']),

    currentSelectedDate(){
      const date = moment.unix(this.selected);
      if(date.isSame(moment(), 'day')){
        return this.$t('bookingDate.today');
      }
      else if(date.isSame(moment().add(1, 'day'), 'day')){
        return this.$t('bookingDate.tomorrow');
      }
      else{
        return date.format('DD MMM YYYY');
      }
    }

  },
  mounted(){
    this.selected = this.modelValue;
  }
});
