import Echo from 'laravel-echo';
import config from "@/config/config";
import Pusher from 'pusher-js'

export default new Echo({
    broadcaster: 'pusher',
    client: new Pusher(config.pusherKey,
        {
            authEndpoint: config.echoAuthUrl,
            enableStats: true,
            cluster: 'eu',
            auth: {
                headers: {Authorization: `Bearer ${localStorage.getItem('bearer')}`}
            }
        })
});
