<template>
    <vue-final-modal
            transition="vfm-slide"
            :ssr="true"
            contentClass="flex justify-center items-end sm:items-center h-full sm:h-auto"
    >
        <div class="w-full sm:w-[500px] bg-white rounded-t-2xl sm:rounded-2xl">
            <div class="flex w-full justify-between items-center h-16 px-6 pt-5 mb-4">
                <span class="font-bold text-lg uppercase flex items-center text-gray-800">{{$t('booking_validation.add_comment')}}</span>
                <button @click="close" class="flex items-center justify-center bg-gray-100 h-10 w-10 rounded-full"><i class="material-icons text-3xl">close</i></button>
            </div>

            <div class="px-6">
                <small class="text-xs text-red-600 font-medium mb-1 block" v-if="errors.some(e => e.error === 'comment')">{{errors.find(e => e.error === 'comment').message}}</small>
                <textarea type="text"
                          v-model="comment"
                          class="form-input min-h-[100px] mb-5"
                          :class="{'border-red-600 bg-red-50': errors.some(e => e.error === 'comment')}"
                          :placeholder="$t('booking_validation.comment_placeholder')"
                ></textarea>

                <button :disabled="isButtonLoading" @click="save" class="bg-primary text-white w-full h-12 uppercase font-bold rounded-lg mb-6 disabled:opacity-75">
                    <span v-if="!isButtonLoading">{{$t('cta.save')}}</span>
                    <app-loader class="h-5" v-else/>
                </button>
            </div>

        </div>
    </vue-final-modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AppLoader from "@/components/AppLoader";
import {VueFinalModal, useVfm} from "vue-final-modal";

export default {
    name: 'BookingComment',
    setup(){
        const vfm = useVfm()
        return {vfm};
    },
    data() {
        return {
            comment: '',
            isButtonLoading: false,
            errors: []
        }
    },
    components: {AppLoader, VueFinalModal},
    methods: {
        ...mapActions(['setCommentBooking']),
        close(){
            this.vfm.closeAll();
        },
        async save(){
            this.isButtonLoading = true;
            await this.setCommentBooking({comment: this.comment, token: this.bookingToken, merchant: this.bookingMerchantSlug})
                .then(() => {
                    this.vfm.closeAll();
                })
                .catch(() => {
                    this.isButtonLoading = false;
                })
        }
    },
    computed: {
        ...mapGetters(['bookingToken', 'bookingMerchantSlug', 'booking'])
    },
    mounted() {
        this.isButtonLoading = false;
        if(this.booking.comment){
            this.comment = this.booking.comment;
        }
    }
}
</script>
