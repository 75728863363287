import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "border-t" }
const _hoisted_2 = { class: "py-4 px-5 overflow-hidden md:flex md:justify-between md:items-center" }
const _hoisted_3 = { class: "text-gray-900" }
const _hoisted_4 = {
  key: 0,
  class: "flex gap-2 mt-3 flex-wrap md:mt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar = _resolveComponent("calendar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "flex justify-between items-center h-8 md:flex-1",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setValue(null)))
        }, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('bookingDate.label')), 1),
          _withDirectives(_createElementVNode("button", {
            class: "btn-action btn-action-active",
            style: _normalizeStyle({'background-color': _ctx.theme.primary, 'border-color': _ctx.theme.primary}),
            id: "booking-date-value"
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.currentSelectedDate), 1)
          ], 4), [
            [_vShow, _ctx.selected]
          ])
        ]),
        (!_ctx.selected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.bookingMerchant.open.today)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn-action",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setValue('today')))
                  }, _toDisplayString(_ctx.$t('bookingDate.today')), 1))
                : _createCommentVNode("", true),
              (_ctx.bookingMerchant.open.tomorrow)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn-action",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setValue('tomorrow')))
                  }, _toDisplayString(_ctx.$t('bookingDate.tomorrow')), 1))
                : _createCommentVNode("", true),
              (_ctx.bookingMerchant.open.tomorrow || _ctx.bookingMerchant.open.today)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "btn-action",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openDate()))
                  }, _toDisplayString(_ctx.$t('bookingDate.other')), 1))
                : (_openBlock(), _createElementBlock("button", {
                    key: 3,
                    class: "btn-action",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openDate()))
                  }, _toDisplayString(_ctx.$t('bookingDate.choose_date')), 1))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_calendar, {
      ref: "calendar",
      onUpdate: _ctx.setValue
    }, null, 8, ["onUpdate"])
  ], 64))
}