<template>
  <button class="flex p-3 text-white w-full items-center px-6 h-16 disabled:opacity-75 validate-button hover:">
    <div class="flex-1 flex uppercase items-center font-bold justify-center" :class="{'opacity-50': disabled}" v-if="!isLoading">
      <span class="uppercase font-bold">{{$t('cta.validate_booking')}}</span>
      <i class="material-icons ml-2">arrow_forward</i>
    </div>
    <div class="flex-1 flex justify-center items-center h-[42px]"  v-if="isLoading">
      <app-loader></app-loader>
    </div>
  </button>
</template>

<style class="" lang="scss">
.validate-button:enabled
{
  animation: btnAnimation 1.5s ease-in-out infinite;
  background-image: linear-gradient(130deg, rgba(#fff,0) 30%, rgba(#fff,0.2) 50%, rgba(#fff,0));
  background-repeat: no-repeat;
}
@keyframes btnAnimation {
  from {
    background-position-x: -150vw;
  }
  to {
    background-position-x: 150vw;
  }
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import AppLoader from "@/components/AppLoader";
export default {
  props: ['isLoading', 'disabled'],
  components: {AppLoader},
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters([])
  }
}
</script>
