
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import anime from "animejs/lib/anime.es.js";

export default defineComponent({
  name: 'BookingSpot',
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    modelValue: {
      default: null
    }
  },
  components: {},
  setup() {
    return {}
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    ...mapActions([]),
    selectSpot(spotId){
      this.selected = spotId;
      this.$store.commit('SET_BOOKING_SLOTS', []);
      this.$emit('update:modelValue', spotId);

      anime({
        targets: '#booking-spot-value',
        translateY: 70,
        duration: 150,
        direction: 'reverse',
        easing: 'easeInCubic'
      });
    }
  },
  computed: {
    ...mapGetters(['bookingZones', 'bookingZoneCount', 'theme']),
    selectedZone(){
      if(!this.selected || this.bookingZones.length === 0) {
        return null;
      }
      return this.bookingZones.find(z => z.id === this.selected).name;
    }
  },
  mounted(){
    this.selected = this.modelValue;
    if(this.selected === 0){
      this.selectSpot('all');
    }

    if(this.bookingZones.length === 1){
      this.selectSpot(this.bookingZones[0].id);
    }
  }
});
