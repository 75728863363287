import axios from '@/axios-instance';
import {i18n} from '@/lang/index'

export default {
    state: {
        bookingMainError: false,
        currentMerchant: null,
        bookingZones: [],
        bookingSlots: [],
        merchantShifts: null,
        bookingToken: null,
        booking: false,
        theme: [],
        merchant: false,
        bookingZoneCount: 0,
        bookingCloses: [],
        currentBookingToken: null,
    },
    mutations: {
        SET_CURRENT_BOOKING_TOKEN(state, token) {
            state.currentBookingToken = token;
        },
        SET_BOOKING_CLOSES(state, closes) {
            state.bookingCloses = closes;
        },
        SET_BOOKING_ZONE_COUNT(state, count){
            state.bookingZoneCount = count;
        },
        SET_MERCHANT(state, merchant){
            state.merchant = merchant
        },
        SET_BOOKING_ZONES(state, zones){
            state.bookingZones = zones;
        },
        SET_BOOKING(state, booking){
            state.booking = booking;
        },
        SET_BOOKING_COMMENT(state, comment){
            state.booking.comment = comment;
        },
        SET_BOOKING_TOKEN(state, token){
            state.bookingToken = token;
        },
        SET_BOOKING_SLOTS(state, slots){
            state.bookingSlots = slots;
        },
        SET_CURRENT_MERCHANT(state, slug){
            localStorage.setItem('current_merchant', slug);
            state.currentMerchant = slug;
        },
        SET_BOOKING_MAIN_ERROR(state, error){
            state.bookingMainError = error;
        },
        SET_MERCHANT_SHIFTS(state, shifts){
            state.merchantShifts = shifts;
        },
        SET_THEME(state, theme){
            state.theme = theme;
        }
    },
    getters: {
        bookingMainError: state => state.bookingMainError,
        bookingZones: state => state.bookingZones,
        bookingSlots: state => state.bookingSlots,
        bookingMerchantSlug: state => state.currentMerchant,
        bookingMerchant: state => state.merchant,
        merchantShifts: state => state.merchantShifts,
        bookingToken: state => state.bookingToken,
        booking: state => state.booking,
        theme: state => state.theme,
        bookingZoneCount: state => state.bookingZoneCount,
        bookingCloses: state => state.bookingCloses,
        currentBookingToken: state => state.currentBookingToken,
    },
    actions: {
        getBookingMerchantInfos({state, commit}){
            return new Promise(((resolve, reject) => {
                axios.post('api/booking/booking_merchant/get', {merchant_slug: state.currentMerchant})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_BOOKING_CLOSES', response.data.closes);
                            commit('SET_MERCHANT_SHIFTS', response.data.shifts);
                            commit('SET_MERCHANT', response.data.merchant);
                            commit('SET_BOOKING_ZONE_COUNT', response.data.zoneCount);
                            commit('SET_THEME', response.data.theme);
                            commit('SET_CURRENT_BOOKING_TOKEN', response.data.currentBookingToken);
                            resolve(response.data);
                        } else {
                            commit('SET_BOOKING_MAIN_ERROR', i18n.global.t('error.'+response.data.error_code));
                            console.log('Error redirect getBookingMerchantInfos');
                            commit('SET_MERCHANT', response.data.merchant);
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        getBookingZones({state, commit}, {date}){
            if(!date){
                return;
            }
            return new Promise(((resolve, reject) => {
                axios.post('api/booking/booking_zones/get', {merchant_slug: state.currentMerchant, date:date})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_BOOKING_ZONES', response.data.bookingZones);
                            resolve(response.data);
                        } else {
                            commit('SET_BOOKING_MAIN_ERROR', i18n.global.t('error.'+response.data.error_code));
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        getBookingDaySlots({state, commit}, {date, bookingZoneId, seats}){
            if(!date || !bookingZoneId){
                // If no date, reset slots
                commit('SET_BOOKING_SLOTS', []);
                return;
            }
            return new Promise(((resolve, reject) => {
                axios.post('api/booking/booking_slots/get', {merchant_slug: state.currentMerchant, date: date, bookingZoneId:bookingZoneId, needed_seats:seats})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_BOOKING_SLOTS', response.data.slots);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        createBooking({state, commit}, {dateTime, zoneId, quantity, token}){
            return new Promise(((resolve, reject) => {
                axios.post('api/booking/create', {token: token, merchant_slug: state.currentMerchant, datetime:dateTime, booking_zone_id:zoneId, client_count:quantity})
                    .then((response) => {
                        if (response.data.success) {
                            localStorage.setItem('token_'+state.currentMerchant, response.data.token);
                            commit('SET_BOOKING_TOKEN', response.data.token);
                            commit('SET_BOOKING', response.data.booking);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        cancelBookingAction({state}, {token, slug}){
            return new Promise(((resolve, reject) => {
                axios.post('api/booking/cancel', {token: token, merchant_slug: slug})
                    .then((response) => {
                        if (response.data.success) {

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        getBookingCloses({commit},{slug: slug}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/booking/closed_booking_calendar', {merchant_slug: slug})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_BOOKING_CLOSES', response.data.closed_days);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        updateBookingUser({state, commit}){
            return new Promise(((resolve, reject) => {
                axios.post('api/booking/update_user', {token: state.bookingToken, merchant_slug: state.currentMerchant})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_BOOKING', response.data.booking);
                            commit('SET_MERCHANT_SHIFTS', response.data.shifts);
                            commit('SET_THEME', response.data.theme);
                            commit('SET_MERCHANT', response.data.merchant);
                            commit('SET_CURRENT_BOOKING_TOKEN', response.data.currentBookingToken);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        getBooking({state, commit}, {token, merchant}){
            return new Promise(((resolve, reject) => {
                axios.post('api/booking/get', {token: token, merchant_slug: merchant})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_THEME', response.data.theme);
                            commit('SET_MERCHANT', response.data.merchant);
                            commit('SET_BOOKING', response.data.booking);
                            resolve(response.data.booking);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        getIcsData({state}, {token, merchant}){
               return new Promise(((resolve, reject) => {
                axios.get('/api/booking/export_ical/'+merchant+'/'+token)
                    .then((response) => {
                        if (response.data.success) {

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        setCommentBooking({state, commit}, {comment, token, merchant}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/booking/set_comment', {token: token, merchant_slug: merchant, comment: comment})
                    .then((response) => {
                        commit('SET_BOOKING_COMMENT', comment);
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        validateBookingAction({state}, {token, merchant}){
            return new Promise(((resolve, reject) => {
                console.log({token: token, merchant_slug: merchant});
                axios.post('api/booking/validate', {token: token, merchant_slug: merchant})
                    .then((response) => {
                        if (response.data.success) {
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        }
    },
}
