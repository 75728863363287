import { createStore } from 'vuex'
import BookingStore from "@/store/BookingStore";
import AuthStore from "@/store/AuthStore";

export default createStore({
  modules: {
    booking: BookingStore,
    auth: AuthStore
  }
})
