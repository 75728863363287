
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import AppPage from "@/components/AppPage.vue";
import {useRouter} from "vue-router";
import config from "@/config/config";
import AppLoader from "@/components/AppLoader.vue";

export default defineComponent({
  name: 'Login',
  components: {AppLoader, AppPage},
  setup() {
    const router = useRouter();
    return {router}
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['getAccessToken', 'getUserAction' , 'getEnabledLoginMethods']),
    back(){
      const merchant = localStorage.getItem('current_merchant');
      this.router.push({name: 'BookingHome',params: {merchantSlug: merchant}});
    },
    connect(type){
      if(!localStorage.getItem('userToken')){
        localStorage.setItem('userToken', (Math.random() + 1).toString(36).substring(7));
      }
      let redirect = encodeURI(location.origin + location.pathname + '?authed=true');
      if(type === 'facebook'){
        return window.location.href = config.loginUrl + '/login/facebook?redirect=' + redirect + '&userToken=' + localStorage.getItem('userToken');
      }
      if(type === 'google'){
        return window.location.href = config.loginUrl + '/login/google?redirect=' + redirect + '&userToken=' + localStorage.getItem('userToken');
      }
    }
  },
  computed: {
    ...mapGetters(['isAuth', 'theme', 'enabledLoginMethods', 'enabledLoginMethodsLoading'])
  },
  async mounted(){
    await this.getEnabledLoginMethods();

    if(this.$route.query.authed){
      await this.getAccessToken()
          .then(() => {

            // get user
            this.getUserAction()
                .then((data) => {
                  if(data.user.onboarding){
                    this.router.push({name: 'Onboarding'})
                  }
                  else{
                    const merchant = localStorage.getItem('current_merchant');
                    const token = localStorage.getItem('token_'+merchant);
                    this.router.push({name: 'Validate', params: {merchantSlug: merchant, token: token}});
                  }
                });
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
});
