
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'LangSelector',
  components: {},
  setup() {
    return {}
  },
  data() {
    return {
      lang: 'fr'
    }
  },
  methods: {
    ...mapActions([]),
    selectLang(){
      this.$i18n.locale = this.lang;
      localStorage.setItem('currentLang', this.lang);
    },
  },
  computed: {
    ...mapGetters([])
  },
  mounted(){
    if(localStorage.getItem('currentLang')){
      this.lang = localStorage.getItem('currentLang');
      this.selectLang();
    }
    else{
      this.lang = this.$i18n.locale;
      this.selectLang();
    }
  }
});
