
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import anime from "animejs/lib/anime.es.js";

export default defineComponent({
  name: 'BookingNumber',
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    modelValue: {
      default: null
    }
  },
  components: {},
  setup() {
    return {}
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    ...mapActions([]),
    setQuantity(value){
      this.selected = value;
      this.$emit('update:modelValue', value);

      anime({
        targets: '#booking-number-value',
        translateY: 70,
        duration: 150,
        direction: 'reverse',
        easing: 'easeInCubic'
      });
    }
  },
  computed: {
    ...mapGetters(['bookingMerchant', 'theme'])
  },
  mounted(){
    this.selected = this.modelValue;
  }
});
