import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Booking from "@/views/Booking.vue";
import Validate from "@/views/Validate.vue";
import NoAccount from "@/views/Auth/NoAccount.vue";
import Onboarding from "@/views/Auth/Onboarding.vue";
import NoAuth from "@/views/Auth/NoAuth.vue";
import Login from "@/views/Auth/Login.vue";
import Register from "@/views/Auth/Register.vue";
import NoMerchant from "@/views/NoMerchant.vue";
import NotFound from "@/views/NotFound.vue";
import BookingComplete from "@/views/BookingComplete.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/:merchantSlug',
    name: 'BookingHome',
    component: Booking
  },
  {
    path: '/auth',
    name: 'NoAuth',
    component: NoAuth,
    meta: {noAuth: true}
  },
  {
    path: '/auth/forgot_password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {noAuth: true}
  },
  {
    path: '/auth/no_account',
    name: 'NoAccount',
    component: NoAccount,
    meta: {noAuth: true}
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    meta: {noAuth: true}
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: Register,
    meta: {noAuth: true}
  },
  {
    path: '/auth/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    meta: {auth: true}
  },
  {
    path: '/:merchantSlug/validate/:token',
    name: 'Validate',
    component: Validate,
    meta: {auth: true}
  },
  {
    path: '/:merchantSlug/booking_complete/:token',
    name: 'BookingComplete',
    component: BookingComplete
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if(to.meta && to.meta.auth === true){
    if(!localStorage.getItem('bearer')){
      return next({name: 'NoAuth'});
    }
  }
  else if(to.meta && to.meta.noAuth === true){
    if(localStorage.getItem('bearer')){
      // If booking have current merchant && current token, redirect to validation
      if(localStorage.getItem('current_merchant') && localStorage.getItem('token_'+localStorage.getItem('current_merchant'))){
        const merchant = localStorage.getItem('current_merchant');
        const token = localStorage.getItem('token_'+localStorage.getItem('current_merchant'));

        return next({name: 'Validate', params: {merchantSlug: merchant, token: token}});
      }
      // If booking have current merchant && no current token, redirect to booking page
      if(localStorage.getItem('current_merchant') && !localStorage.getItem('token_'+localStorage.getItem('current_merchant'))){
        return next({name: 'BookingHome', params: {merchantSlug: localStorage.getItem('current_merchant')}});
      }
      // Else no merchant ? error.
      return next({name: 'NotFound'});
    }
    else{
      return next();
    }
  }
  return next();
});


export default router
