<template>
  <div class="w-full" :style="{'background-color': theme.primary}">
    <div class="p-8 flex border-b border-[rgba(255,255,255,0.1)]">
      <div class="flex flex-col flex-1">
        <h2 class="text-white text-3xl font-bold mb-2">{{bookingMerchant.name}}</h2>
        <span class="text-lg text-white">{{bookingMerchant.address.address}}</span>
        <span class="text-lg text-white" v-if="bookingMerchant.address.address2">{{bookingMerchant.address.address2}}</span>
        <span class="text-lg text-white">{{bookingMerchant.address.zip}} {{bookingMerchant.address.city}}</span>
        <a :href="'tel:'+bookingMerchant.phone" class="text-lg text-white">{{bookingMerchant.phone}}</a>
      </div>
      <div>
        <img :src="bookingMerchant.logo.path" alt="" class="h-[90px] w-[90px] rounded-full border-[5px] border-white">
      </div>
    </div>

    <div class="p-8">
      <div v-for="(shift,i) in merchantShifts" :key="i" class="text-sm md:text-base flex text-white mb-1" :class="{'font-bold': shift.day === currentDayIso}">
        <div class="w-24 md:w-28">{{$t('days.'+shift.day)}}</div>
        <div v-if="shift.slots.length">
          <span v-for="slot in shift.slots" :key="slot.id" class="mr-4">{{slot.start}} - {{slot.end}}</span>
        </div>
        <div v-if="!shift.slots.length">
          <span class="text-[rgba(255,255,255,0.3)] font-medium">{{$t('calendar.closed')}}</span>
        </div>
      </div>
    </div>

  </div>
  <div class="w-full bg-[#1C1D1B] flex justify-between px-6">
    <span class="flex items-center text-xs text-[rgba(255,255,255,0.8)]">{{$t('page.madewith')}} <img src="/img/heart.png" class="h-3 mx-1" alt=""> {{$t('page.at')}}</span>
    <a href="https://delicity.com/" target="_blank" class="flex items-center h-10 bg-primary px-2">
      <img src="https://delicity.com/img/bag.svg" class="h-8">
      <img src="https://delicity.b-cdn.net/front/img/new_logo_white.svg" class="h-5">
    </a>
  </div>
  <div class="w-full bg-black p-3 text-[#C1C6C0] text-xxs text-center">
    <span>&copy; 2023 Delicity · <a href="https://pro.delicity.com/cgv/" target="_blank">CGV</a> · <a href="https://pro.delicity.com/legal/" target="_blank">Mentions légales</a> </span>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {defineComponent} from "vue";
import moment from '@/moment-instance'

export default defineComponent({
  name: 'MerchantFooter',
  setup(){
    const currentDayIso = moment().isoWeekday()
    return {currentDayIso}
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['theme', 'bookingMerchant', 'merchantShifts'])
  }
});
</script>
