
import {mapActions, mapGetters} from "vuex";
import {defineComponent, ref} from "vue";
import AppPage from "@/components/AppPage.vue";
import {useRouter} from "vue-router";


export default defineComponent({
  name: 'Onboarding',
  components: {AppPage},
  setup() {
    const router = useRouter();
    const phoneElement = ref(null);
    return {router, phoneElement}
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      phone: '',
      phoneNumber: {},
      email: '',
      errors: [],
      isLoading: true,
      isButtonLoading: false,
      isEmailDisabled: true
    }
  },
  methods: {
    ...mapActions(['getUserAction', 'saveOnboardingAction']),
    back(){
      this.router.back();
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    validate(){
      this.errors = [];

      if(this.firstname === ''){
        this.errors.push({error: 'firstname'})
      }
      if(this.lastname === ''){
        this.errors.push({error: 'lastname'})
      }
      if(this.phone === ''){
        this.errors.push({error: 'phone', message: this.$t('form_error.empty_phone')})
      }
      if(this.email !== '' && this.email !== null && !this.validateEmail(this.email)){
        this.errors.push({error: 'email'})
      }

      if(this.errors.length > 0){
        return false;
      }
      return true;
    },
    updatePhone(e,v){
      if(v.valid === true){
        this.phone = v.formatted;
      }
    },
    save(){
      if(!this.validate()){
        return false;
      }



      this.isButtonLoading = true;
      this.saveOnboardingAction({firstname: this.firstname, lastname: this.lastname, email: this.email, phone: this.phone})
          .then(() => {
            const merchant = localStorage.getItem('current_merchant');
            const token = localStorage.getItem('token_'+merchant);
            this.router.push({name: 'Validate', params: {merchantSlug: merchant, token: token}});
          })
          .catch((errors) => {
            this.parseErrors(errors);
            this.isButtonLoading = false;
          })

    },
    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
    }
  },
  computed: {
    ...mapGetters(['theme'])
  },
  mounted(){
    this.isLoading = true;
    this.getUserAction()
        .then((data) => {
          this.firstname = data.user.firstname;
          this.lastname = data.user.lastname;
          this.phone = data.user.phone;
          this.isEmailDisabled = true;
          if(data.user.anonymous === true){
            this.email = data.user.anonymousEmail;
            this.isEmailDisabled = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
  }
});
