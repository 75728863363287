
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import {useRouter} from "vue-router";
import AppPage from "@/components/AppPage.vue";

export default defineComponent({
  name: 'Register',
  components: {AppPage},
  setup() {
    const router = useRouter();
    return {router}
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      phone: '',
      password: '',
      showPassword: false,
      email: '',
      errors: [],
      isButtonLoading: false
    }
  },
  methods: {
    ...mapActions(['registerAction']),
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    validate(){
      this.errors = [];

      if(this.firstname === ''){
        this.errors.push({error: 'firstname'})
      }
      if(this.lastname === ''){
        this.errors.push({error: 'lastname'})
      }
      if(this.phone === ''){
        this.errors.push({error: 'phone'})
      }
      if(this.password === ''){
        this.errors.push({error: 'password'})
      }
      if(this.email === ''){
        this.errors.push({error: 'email'})
      }
      if(!this.validateEmail(this.email)){
        this.errors.push({error: 'email', message: this.$t('form_error.bad_email')})
      }

      if(this.errors.length > 0){
        return false;
      }
      return true;
    },
    back(){
      this.router.back();
    },
    save(){
      if(!this.validate()){
        return;
      }

      this.isButtonLoading = true;
      this.registerAction({firstname: this.firstname, lastname: this.lastname, phone: this.phone, email: this.email, password: this.password})
          .then(() => {
            const merchant = localStorage.getItem('current_merchant');
            const token = localStorage.getItem('token_'+merchant);
            this.router.push({name: 'Validate', params: {merchantSlug: merchant, token: token}})
          })
          .catch((errors) => {
            this.isButtonLoading = false;
            this.parseErrors(errors);
          });
    },

    parseErrors(errors){
      this.errors = [];
      for(const e in errors){
        this.errors.push({error:e, message: errors[e][0]});
      }
    }
  },
  computed: {
    ...mapGetters(['theme'])
  }
});
