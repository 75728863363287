
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import AppPage from "@/components/AppPage.vue";
import config from "@/config/config";
import EchoInstance from "@/echo-instance";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'BookingComplete',
  components: {},
  setup() {
    const router = useRouter();
    return {router}
  },
  data() {
    return {
      isLoading: true
    }
  },
  methods: {
    ...mapActions(['getBooking', 'getIcsData']),
    addToCalendar(){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.getIcsData({token: this.$route.params.token, merchant: this.$route.params.merchantSlug})
            .then((data) => {
              var link = document.createElement('a');
              link.href = "data:text/calendar;charset=utf8," + encodeURI(data.ics);
              link.download = 'event.ics';
              link.click();
              link.remove();
            })
      }
      else{
        window.open(this.booking.calendarUrl, '_blank');
      }
    },
    updateBooking(){
      this.router.push({name: 'BookingHome', params: {merchantSlug: this.$route.params.merchantSlug}, query: {token: this.$route.params.token}});
    },
    share(){
      if (navigator.share) {
        navigator.share({
          text: 'Voici ma reservation Delicity chez ' + this.bookingMerchant.name,
          url: location.href
        }).catch(console.error);
      }
    },
    listen(){
      EchoInstance.channel('booking.'+this.booking.token)
          .listen('Booking\\UpdateBooking', (e) => {
            this.getBooking({token: this.$route.params.token, merchant: this.$route.params.merchantSlug});
          });
    },
    selectLang(lang){
      this.$i18n.locale = lang;
      localStorage.setItem('currentLang', lang);
    }
  },
  computed: {
    ...mapGetters(['booking', 'bookingMerchant', 'theme', 'bookingMerchantSlug']),
    isSharable(){
      return navigator.share ? true : false;
    },
    backUrl(){
      return document.referrer !== ''  ? document.referrer : this.bookingMerchant.url;
    },
    statusColor(){
      if(this.booking.status === 10){
        return "bg-yellow-500";
      }
      if(this.booking.status === 20){
        return "bg-green-400";
      }
      if(this.booking.status === 25){
        return "bg-red-400";
      }
      if(this.booking.status === 30){
        return "bg-green-400";
      }
      if(this.booking.status === 40){
        return "bg-red-400";
      }
      return '';
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getBooking({token: this.$route.params.token, merchant: this.$route.params.merchantSlug})
    this.isLoading = false;

    this.listen();
  }
});
