
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import anime from "animejs/lib/anime.es.js";
import AppLoader from "@/components/AppLoader.vue";
import {DateTime} from "luxon";

export default defineComponent({
  name: 'BookingSchedule',
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    modelValue: {
      default: null
    }
  },
  components: {AppLoader},
  setup() {
    return {}
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    ...mapActions([]),
    selectSchedule(value){
      this.selected = value;
      this.$emit('update:modelValue', value);

      anime({
        targets: '#booking-schedule-value',
        translateY: 70,
        duration: 150,
        direction: 'reverse',
        easing: 'easeInCubic'
      });
    }
  },
  computed: {
    ...mapGetters(['bookingSlots', 'theme']),
    currentSelected(){
      if(!this.selected){
        return null;
      }
      if(this.bookingSlots.length > 0){
        return this.bookingSlots.find(b => b.timestamp === this.selected).hour;
      }
      else if(this.selected){
        return DateTime.fromSeconds(this.selected).toFormat('HH:mm');
      }
      return null;
    }
  },
  watch:{
    modelValue(val){
      this.selected = val;
    }
  },
  mounted(){
    this.selected = this.modelValue;
  }
});
