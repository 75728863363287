export default {
  booking: {
    title: "Reservar una mesa",
    meta: "Reserva de mesa en {merchant}"
  },
  alreadyBook: {
    title: "Ya tienes una reserva vigente",
  },
  page:{
    madewith: "Hecho con",
    at: "en Niza"
  },
  bookingDate: {
    label: 'Fecha de reserva',
    today: "Hoy dia",
    tomorrow: "Mañana",
    other: "Otra fecha",
    choose_date: "Seleccionar una fecha"
  },
  bookingNumber: {
    label: 'Número de personas',
    quantity: 'una persona|{count} personas'
  },
  bookingSpot: {
    label: 'Eleccion de habitacion',
    all: 'Todo me queda bien'
  },
  bookingSchedule: {
    label: 'Cada hora'
  },
  forgotPassword: {
    title: 'contraseña olvidada',
    cta: 'Restablecer mi contraseña',
    success: "Se ha enviado un correo electrónico para restablecer su contraseña."
  },
  cta: {
    book: 'Reservar',
    save: "Registro",
    login: "Iniciar sesión",
    update: "Editar",
    register: "Registro",
    choose: "Elección",
    cancel: "Cancelar",
    cancel_booking: "Cancelar reserva",
    continue: "Continuar",
    validate_booking: "Validar reserva"
  },
  form: {
    your_firstname: 'Vuestro nombre',
    your_lastname: 'Su nombre',
    phone: "Teléfono",
    phone_required: "*Obligatorio contactarlo en caso de problema.",
    email: "Correo electrónico",
    password: "Contraseña"
  },
  no_auth: {
    description: "Necesitamos sus datos de contacto para comunicarnos con usted si hay algún problema.",
    login_with_facebook: "Continuar con Facebook",
    login_with_google: "Continuar con Google",
    login_with_platform: "Tengo una cuenta de Delicity",
    login_without_account: "Continuar sin una cuenta",
  },
  login: {
    title: "Tu cuenta de Delicity",
    forgot_password: "contraseña olvidada",
    create_platform_account: "Crea una cuenta Délicity"
  },
  register: {
    title: "Crear una cuenta"
  },
  onboarding: {
    title: 'Su dirección',
    email_used_for_invoice: "Utilizado para enviarle su factura",
    email_used_for_summary: "Se le enviará una confirmación por SMS después de la validación."
  },
  no_account: {
    title: "Su dirección",
    receive_invoice: "Me gustaría recibir mi factura por correo electrónico.",
    receive_confirm: "Me gustaría recibir una confirmación por correo electrónico.",
    email_necessary: "Obligatorio si desea recibir la factura por correo electrónico"
  },
  not_found: {
    title: "Esta página no existe."
  },
  booking_complete: {
    title: "¡Tu reserva ha sido enviada al restaurante!"
  },
  booking_validation: {
    no_email: "Sin correo electrónico",
    title: 'A validar',
    condition_1: 'Acepto las',
    condition_2: 'condiciones generales de venta',
    condition_3: 'por {merchant} and Delicity',
    validate: "Confirmar reservación",
    add_comment: "añadir un comentario",
    update_comment: "actualizar un comentario",
    comment: "Comentario : {comment}",
    your_booking: "Tu reservacion",
    your_infos: "Su dirección",
    date: "{date} a {hour}",
    persons: "{count} persona · {spot}| {count} personas · {spot}",
    persons_no_zone: "una persona| {count} personas",
    comment_placeholder: "Agrega una nota..."
  },
  calendar: {
    title: 'Elige una fecha',
    validate: 'Selecciona el {date}',
    closed: 'Cerrado',
  },
  form_error: {
    bad_password: "La contraseña es incorrecta o la cuenta no existe.",
    empty_phone: "Necesitamos un número de teléfono para comunicarnos con usted si hay algún problema.",
    bad_email: "Email inválido"
  },
  error: {
    field_required_for_saving: 'Este campo es obligatorio para guardar.',
    max_clients_per_booking_exceeded: "Número máximo de clientes excedido para esta reserva.",
    last_minute_booking_not_allowed: "Las reservas de última hora no son posibles en este restaurante.",
    booking_not_activated: "La reserva aún no está activada para este restaurante.",
    booking_setting_not_found: "La reserva aún no está activada para este restaurante.",
    booking_redirect: "Serás redirigido en unos segundos...",
    merchant_not_found: "Este restaurante no existe.",
    booking_zone_not_exist: "El área de reservas no existe o ya no existe."
  },
  bookingTracking: {
    booking_at: "{date} a las {hour}",
    add_calendar: "Añadir al calendario",
    share_booking: "Compartir reserva",
    update_booking: "Modificar reserva",
    problem: "Problema con la reserva",
    how_its_work: "Marcha",
    how_its_work_text: "Recibirá un SMS tan pronto como se confirme su reserva. El restaurador se compromete a mantener una mesa hasta {duration} minutos después de la hora prevista.",
    route: 'itinerario',
    merchant_currently_checking: "El restaurante comprueba su disponibilidad y confirmará tu reserva",
    merchant_currently_unavailable: "El restaurante aún no ha validado tu reserva. Puedes intentar adjuntarlo a {phone}.",
    current_status: "Estado actual",
    ref: "Referencia"
  },
  status: {
    "10": "Para confirmar",
    "20": "Confirmado",
    "25": "rechazado",
    "30": "Terminado",
    "40": "Cancelado",
  },
  bookingUpdate: {
    title: "Modificar reserva",
  },
  bookingCancel: {
    other: "El restaurante canceló su reserva.",
    closed: 'El restaurante estará cerrado.',
    full: 'el restaurante esta lleno',
    client_cancel: 'Has cancelado la reserva.',
    merchant_message: 'Mensaje del restaurante: {message}',
    booking_canceled: "Su reserva ha sido cancelada.",
  },
  days: {
    1: "Lunes",
    2: "Martes",
    3: "Miércoles",
    4: "Jueves",
    5: "Viernes",
    6: "Sábado",
    7: "Domingo"
  }
}
